import React from 'react';
import Markdown from 'markdown-to-jsx';
import { graphql } from 'gatsby';
import { Grid } from 'semantic-ui-react';
import Helmet from 'react-helmet';

import { PageLayout, HeroElement, About, LocationsFilter } from '@fishrmn/fishrmn-components';
import Layout from '../components/Layout';


export default class extends React.PureComponent {

  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    const sliderImages = [];
    data.heroImages.slice(4, 8).forEach(imgObj => {
      sliderImages.push(imgObj.url);
    });

    data.locations.forEach(location => {
      location.phone_number = location.phoneNumber;
    });

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Home</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={ "KFC Caters" }
              tagline={ "Our Northern and Mid Michigan KFC restaurants have been catering events and gatherings for over three decades. Whether you’re putting together boxed lunches for a business meeting, planning a family reunion or graduation open house, or just need to feed a group of people, we can help. Complete meals start at just $6.99 and we’ll do all the work while you have all the fun." }
              images={ sliderImages }
              showMap={ false }
              ctas={[]}
              ctaInverted={ true }
              ctaColor={ "white" }
              containerColor={ "primary" }
              textColor={ "white" }
              showMultiple={ true }
              overlay={ true }
              fullWidth={ true }
              parallax={ true }
              containerAs={ "none" }
              height={ "100vh" }
              gutter={ false }
            />
           }
        >
          <Grid
            id="home-welcome-kfc"
            stackable
            className="component-section-container"
            verticalAlign={ "middle" }
            textAlign={ "center" }
          >
            <Grid.Column width={ 16 }>
              <About
                style={{border: '4px solid red !important'}}
                content={ <Markdown>{data.aboutMarkdown}</Markdown> }
                header={ `Welcome to ${data.businessName}` }
                centerContent={ true }
                headerAs={ "h1" }
                withContainer={ true }
              />
            </Grid.Column>
          </Grid>

          <Grid
            id="locationgrid"
            stackable
            className="component-section-container"
            verticalAlign={ "top" }
            textAlign={ "center" }
          >
            <Grid.Row>
              <Grid.Column width={16}>
                <LocationsFilter locations={data.locations} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown          locations {
            address {
              city
              state
              street
              zip
            }
            id
            name
            phoneNumber
            primaryLocation
            timezone
          }
          reviews {
            name 
            content 
            link 
          }
          ctas {
            phone {
              link 
              text 
              internal 
            }
          }
          address {
            street 
            city 
            state 
            zip 
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url 
          }
          heroImages {
            url 
          }
        }
      }
    }
  }
`;
